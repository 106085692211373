@use "sass:map";
@import "../../styles/constants/breakpoints";
@import "../../styles/constants/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";

.containerLeft {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.containerRight {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.cpMenu {
  width: 150px;
  height: calc(100svh - 64px);
  background-color: map.get($cptColors, background);
  color: map.get($cptColors, white);
  font-family: $font-family-text-font;
  font-size: $font-size-base;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-left;
  text-decoration: none;
}

@media #{$smMedia} {
  .cpMenu {
    position: relative;
  }
}

.caratIcon {
  display: flex;
  width: $icon-size-regular;
  height: $icon-size-regular;
  align-items: center;
  padding-left: $gap-2;
  margin-left: auto;
  fill: map.get($cptColors, white);
}

.caratIconReverse {
  padding-right: $gap-2;
  padding-left: 0;
  transform: rotate(180deg) scaleX(1);
}

.switchBookContainer {
  padding-left: $gap-2;
}


.cpSmall {
  width: 80px;

  p {
    display: none;
  }

  .cpMenuItem:hover .cpMenuIcon {
    background-color: map.get($primary, primary90);
  }

  .cpMenuItem:focus .cpMenuIcon {
    box-shadow: $box-shadow-focus;
    transition: background-color 300ms, box-shadow 300ms, color 300ms;
  }

  .cpMenuItem:active .cpMenuIcon {
    background-color: map.get($cptColors, white);
    fill: map.get($cptColors, dark_blue);
  }

  .cpMenuItem:active .cpMenuIcon svg {
    fill: map.get($cptColors, background);
  }

  .cpMenuItem .cpMenuIconSelected,
  .cpMenuItem:hover .cpMenuIconSelected {
    background-color: map.get($cptColors, white);
  }

  .cpMenuIcon svg {
    width: 24px;
    fill: map.get($cptColors, white);
  }

  .cpMenuIconSelected svg {
    fill: map.get($cptColors, dark_blue);
  }

  .cpMenuSlider {
    display: none;
  }

  .cpMenuSliderSmall {
    display: flex;
    justify-content: center;
    padding: $gap-2 $gap-1;
  }
}

.cpMenuIcon {
  display: flex;
  width: $icon-size-regular;
  height: $icon-size-regular;
  box-sizing: content-box;
  align-items: center;
  justify-content: center;
  padding: $gap-1 $gap-3;
  border-radius: $border-radius-xlarge;
  margin-bottom: $gap-1;
}

.cpMenuTitle {
  background-color: map.get($cptColors, yellow);
  cursor: pointer;
  text-align: center;

  p {
    display: block;
    padding: 6px;
    color: map.get($cptColors, background);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.017em;
    line-height: 150%;
  }
}

.cpMenuItem {
  display: flex;
  min-height: 30px;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  padding: $gap-2 $gap-1;
  margin-top: $gap-1;
  color: map.get($cptColors, white);
  cursor: pointer;
  font-family: $font-family-text-font;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-center;
  text-decoration: none;
}

.cpMenuItemDisabled {
  display: flex;
  min-height: 30px;
  box-sizing: content-box;
  flex-direction: column;
  align-items: center;
  padding: $gap-2 $gap-1;
  margin-top: $gap-1;
  color: map.get($cptColors, white);
  cursor: not-allowed;
  font-size: $font-size-xsmall;
  font-style: normal;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  opacity: 0.5;
}

.cpMenuItem .cpMenuIconText,
.cpMenuItemDisabled .cpMenuIconText {
  color: map.get($cptColors, white);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 120%;
  text-align: center;
  text-decoration: none;
}

.cpMenuSlider {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid map.get($cptColors, white);
  margin-right: 20px;
  margin-left: 20px;
}

.cpToggleButtonWrapper {
  display: flex;
  width: 60%;
  align-items: center;
  justify-content: center;
}

.cpMenuDirection {
  margin-top: 10px;
}

.cpMobileView {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: map.get($cptColors, background);
  color: map.get($cptColors, white);
}

.cpCloseButton {
  width: 40px;
  height: 40px;
  padding: 5px;
  border: 1px solid map.get($cptColors, light_blue2);
  border-radius: 10px;
  background-color: map.get($cptColors, background);
  color: map.get($cptColors, white);

  svg {
    width: 17px;
    height: 31px;
    margin-left: 0;
    fill: map.get($cptColors, white);
  }
}

.cpCloseMenuButton {
  height: $icon-size-large;
  flex-direction: row-reverse;
  margin-top: 0;
}

.cpMenuLabel {
  margin-top: $gap-1;
  color: map.get($base, white100);
  font-family: $font-family-text-font;
  font-size: $font-size-xsmall;
  font-weight: $font-weight-400;
  letter-spacing: $letter-spacing-0;
  line-height: $line-height-120;
  text-align: $text-align-center;
  text-decoration: none;
}

.cpMenuParent {
  width: 100%;
}

@media #{$xsMedia} {
  .cpDesktopView {
    display: none;
  }

  .cpMobileView {
    display: block;
    height: 100vh;
    padding: $gap-4;
  }

  .cpMenuItem,
  .cpMenuItemDisabled {
    height: $icon-size-regular;
    padding: $gap-2 $gap-4;
  }

  .cpCloseMenuButton {
    height: $icon-size-large;
  }

  .cpMenuIcon {
    padding: 0;
    margin: 0;
  }

  .cpMenuIcon svg {
    width: $icon-size-regular;
    height: $icon-size-regular;
    fill: map.get($cptColors, white);
  }

  .cpMenuLabel {
    margin-top: 0;
    margin-left: $gap-2;
    color: map.get($cptColors, white);
    font-family: $font-family-text-font;
    font-size: $font-size-base;
    font-weight: $font-weight-400;
    letter-spacing: $letter-spacing-0;
    line-height: $line-height-120;
    text-align: $text-align-center;
    text-decoration: none;
  }

  .cpHeaderRow {
    padding: 0;
  }

  .modalLeftMenu {
    margin-left: 0 !important;
  }
}

@media #{$smMedia} {
  .cpDesktopView {
    display: block;
  }

  .cpMobileView {
    display: none !important;
  }
}

.modalLeftMenu {
  position: absolute;
  margin-left: 82px;
}

.modalRightMenu {
  position: absolute;
  margin-right: 82px;
}

.customPopupOverlay {
  &::before,
  &::after {
    display: none;
  }
}

.printViewModal {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

@media #{$smMedia} {
  .cpMenuParent {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media #{$smTabletMedia} {
  .cpMenuItem {
    flex-direction: row;
  }

  .cpCloseMenuButton {
    flex-direction: row-reverse;
  }

  .cpMenuItemDisabled {
    flex-direction: row;
  }
}
