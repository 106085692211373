@use "sass:map";
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";
@import "~@oup/shared-front-end/dist/styles/themes/base/colors";
@import "~@oup/shared-front-end/dist/styles/themes/base/mixins/box-shadow";
@import "~@oup/shared-front-end/dist/styles/themes/base/typography";
@import "~@oup/shared-front-end/dist/styles/breakpoints";
@import "../../styles/constants/breakpoints";

.container {
  display: flex;
  width: 375px;
  height: calc(100vh - 64px);
  flex-direction: column;
  align-items: flex-start;
  padding: $gap-4;
  margin: 0;
  background-color: map.get($base, white100);
  box-shadow: 13px 10px 19px map.get($base, white20);
  color: map.get($primary, primary100);
  overflow-y: scroll;

  h2 {
    margin: 0 0 15px;
    color: map.get($primary, primary100);
    font-size: $font-size-large;
    font-style: normal;
    font-weight: $font-weight-700;
    letter-spacing: -0.017em;
    line-height: 150%;
  }

  li {
    color: map.get($primary, primary100);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: -0.017em;
    line-height: 150%;
  }
}

@media screen and (max-width: $screen-width-600) {
  .container {
    height: 100vh;
    padding-bottom: 75px;
  }
}

@media screen and (max-width: $phoneBreakpoint) {
  .container {
    width: 100vw;
  }
}

.subMenuContainer {
  width: 100%;
  padding-left: $gap-4;
}

.menuItem {
  display: flex;
  min-height: $icon-size-large;
  align-items: center;
  justify-content: space-between;
  padding: $gap-2, $gap-4;
  padding: $gap-2 $gap-4 $gap-2 $gap-4;
  border-radius: $border-radius-default;
  margin-bottom: $gap-1;
  background-color: map.get($base, white0);
  color: map.get($primary, primary100);
  cursor: pointer;
  gap: $gap-2;

  .svgIcon {
    fill: map.get($primary, primary100);
  }

  &:hover {
    background-color: map.get($primary, primary10);
  }
}

.menuItem:active,
.menuItemExtended {
  font-weight: bold;
}

.caratIcon {
  display: flex;
  width: $icon-size-regular;
  height: $icon-size-regular;
  align-items: center;
  padding-left: $gap-2;

  svg {
    min-width: $icon-size-small;
    min-height: $icon-size-small;
  }
}

.caratIconReverse {
  padding-right: $gap-2;
  padding-left: 0;
  transform: rotate(180deg) scaleX(1);
}

.titleContainer {
  width: 100%;
}

.cpCloseButton {
  width: $icon-size-large;
  height: $icon-size-large;
  padding: 0;
  margin-left: auto;
  color: map.get($primary, primary100);
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
}

.svgIconLockPrimary {
  width: 20px;
  height: 20px;
  fill: map.get($primary, primary100);
}

.svgIconLockWhite {
  width: 20px;
  height: 20px;
  fill: white;
}

.svgIconLockContainer {
  display: flex;
  align-items: center;
  padding-left: $gap-2;
}

.greyTitle {
  opacity: 0.5;
}
