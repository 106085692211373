@import '../../../../styles/constants/colors';
@import "~@oup/shared-front-end/dist/styles/themes/base/sizes";

.contentPreviewWrapper {
  overflow: auto;
  width: 97vw;
}

.transform {
  transform: translateX(calc((78rem - 100vw) / 2));
}

.contentPreviewTableContainer {
  overflow: auto;

  th {
    width: auto !important;
    padding: 10px;
    text-align: left !important;
    white-space: nowrap;
  }

  th:last-child {
    text-align: center !important;
  }

  td {
    width: auto !important;
    padding: 10px;
    font-weight: normal;
    text-align: left !important;

    img {
      width: 40% !important;
      padding: 10px;
      font-weight: normal;
      text-align: left !important;
    }
  }

  button {
    color: $color-primary-blue !important;
    font-weight: bold;
  }

  table {
    input {
      width: 250px;
    }

    label {
      margin: 0;
    }
  }
}

.filters {
  width: 100%;
  margin-bottom: 20px;
}

.filters::after {
  display: table;
  clear: both;
  content: "";
}

.filter {
  width: 49%;
  float: left;
}

.filter:nth-child(2) {
  float: right;
}

.sourceFilter {
  width: 30%;
  max-width: 150px;
  float: right;
}

.resultsNumber {
  width: 30%;
  max-width: 150px;
  margin-right: 20px;
  float: right;
}

.alertContainer {
  margin-bottom: 20px;
}

.previewButtonsContainer {
  display: flex;
  flex-direction: column;
}

.coverImage {
  width: 100%;
  height: auto;
}

.tableAccordionPosition {
  position: relative;
  width: 97vw;
}

button[id="confirmationModalNegativeButton"] {
  margin-left: $gap-1;
}

.gamesPreviewLayer {
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: 100vh;

  .gamesPreviewHeader {
    width: 100%;
    height: 50px;
    padding-right: 30px;
    background: #FFF;
    line-height: 50px;
    text-align: right;
  }

  &.open {
    display: block;
  }

  iframe {
    width: 100vw;
    height: calc(100% - 50px);
  }
}
