@use "sass:map";
@import "../../styles/constants/colors";

.productListing {
  .subscription {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    color: map.get($hubColors, dark-gray);
    font-size: 14px;
    line-height: 20px;
  }

  .translucent {
    filter: grayscale(1);
  }

  .image {
    position: relative;
    overflow: hidden;
    min-width: fit-content;
    margin-right: 16px;

    img {
      width: auto;
      height: 50px;
    }
  }

  .title {
    font-size: 16px;

    button {
      color: map.get($background-and-border-colors, background-light-blue);
    }
  }

  .titleIcon {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-left: 0.25em;
    fill: map.get($background-and-border-colors, background-light-blue);
  }

  .titleSub {
    color: $color-sub-text;
    font-size: 13px;
  }

  .expiring {
    color: map.get($hubColors, hub_expiring_licence);
  }

  .expired {
    color: map.get($secondary-colors, classroom);
  }

  .noLicence {
    color: map.get($secondary-colors, classroom);
  }

  .titleWrap {
    flex: auto;
  }

  .badgeWrap button {
    padding: 0 5px;

    img {
      width: 100px;
      height: 33px;
    }
  }

  .collectionBook {
    border-bottom: ridge;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .linkSpace {
    margin-left: 3px;
    color: black;
  }
}
